import OidcRedirectHandler from "../oidcRedirectHandler"
import LoadingIndicator from "../components/loading-indicator"
import {
    demoDashboardMenuList,
    betaDashboardMenuList,
} from "../components/custom-dashboards/settings-files/settings"
import {
    CompanyAdminEquipmentIndexRedirect,
    CompanyAdminIndexRedirect,
    CompanyAdminMaterialsIndexRedirect,
    RelativeEditIndexRedirect,
    RelativeWeeklyIndexRedirect,
} from "./index-redirects"
import {
    CmicIntegration,
    CompanyPicklists,
    CompanySignatures,
    CostCodesUploadFromCSVForm,
    CustomDashboard,
    CustomFormWrapper,
    DailySummaryEmailSettings,
    DailyTimeCard,
    EmployeesUploadFromCSVForm,
    EquipmentUploadFromCSVForm,
    ExportPage,
    ForemenInvite,
    ForgotPassword,
    GuestLoginPage,
    GroupMembersCSVUploadForm,
    Integrations,
    LockedTimePeriod,
    LoginPage,
    LoginLockoutPage,
    MassText,
    MaterialsUploadFromCSVForm,
    PageForbidden,
    PageNotFound,
    ProjectConfigDashboard,
    ProjectPage,
    ProjectsUploadFromCSVForm,
    ProtectedApiIntegration,
    QuantityEdit,
    ReportsPageContainer,
    ResetPassword,
    RoleNotAvailablePage,
    SetNewPassword,
    SetPassword,
    TKTextToSignRouter,
    TKSignatureRouter,
    // TKSignatureWrapper, // can be activated we remove WA-5644-tk-signatures and remove TKSignatureRouter
    TrialDeactivated,
    CompanyLogoUpload,
    DailyProfitLoss,
    SSOConfiguration,
} from "./lazy-components"
/** Const */
import { GUEST_PATH, PDF_PATH, adminAndPmRoles, navCategories } from "../common/constants"
import { getFlagEnabled } from "../getFlagValue"

// Breacrumb icons
import accountSettingsIconSrc from "../../images/sidebar_icons/account-settings.svg"
import analyticsIconSrc from "../../images/sidebar_icons/analytics.svg"
import companySettingsIconSrc from "../../images/sidebar_icons/company-settings.svg"
import dailySummaryEmailSettingsIconSrc from "../../images/sidebar_icons/email.svg"
import demoBetaIconSrc from "../../images/sidebar_icons/demo-beta.svg"
import fieldFormsIconSrc from "../../images/sidebar_icons/field-forms.svg"
import projectSettingsIconSrc from "../../images/sidebar_icons/project-settings.svg"
import reportsIconSrc from "../../images/sidebar_icons/reports.svg"
import timeCardsIconSrc from "../../images/sidebar_icons/time-cards.svg"
import {
    tRoute,
    tMenuItem,
    tNavCategoriesKeys,
    tHasEmailNotificationAccessProps,
    tMenuCategoriesRoutes,
} from "./types"
import { tFeatures, tUserRole } from "../common/types"
import MaterialCSVUploadForm from "../forms/upload/material-upload-csv"
import {
    picklistDashboardRoutePath,
    projectConfigDashboardRoutePath,
    projectDashboardAddNewRoutePath,
    projectDashboardRoutePath,
    projectDashboardUploadRoutePath,
} from "./constants"
import standardIcons from "../toolbar/Toolbar/icons"

// Generate demo/beta dashboard route configs dynamically
const generateDashboardEntries = (
    menuList: tMenuItem[],
    prefix: string,
    category: tNavCategoriesKeys
): Array<tRoute> =>
    menuList.map(route => ({
        path: `${prefix}${route.path}`,
        authRequired: true,
        component: CustomDashboard,
        settings: route.settingsKey,
        wrapper: ReportsPageContainer,
        navTitle: route.tableName,
        navIconSrc: demoBetaIconSrc,
        navCategory: navCategories[category],
        navCondition: (props: any) => props.features[`custom_dashboards_${category}`],
    }))

const hasEmailNotificationAccess = (props: tHasEmailNotificationAccessProps): boolean =>
    adminAndPmRoles.has(props.user_role as string) && props.company_role !== "OWNER"

/**
 * Route definition
 *
 * ORDER MATTERS. These routes are rendered in the order they are defined here,
 * and React router will render the first route component that matches the
 * current URL path. If you are defining nested routes, the child routes must
 * be defined BEFORE their parents ("/foo/bar" THEN "/foo"). For items that
 * appear in the navigation menu, this order will also dictate their order in
 * the menu.
 *
 * PERFORMANCE MATTERS. You should only be using lazy-loaded components for
 * routes unless you have a specific, compelling reason not to.
 *
 * Options:
 *     - `path`         - any valid URL or array of URL patterns that
 *                        path-to-regexp@^1.7.0 understands. Routes without
 *                        paths ALWAYS match the current URL.
 *     - `authRequired` - true if unauthenticated users should be redirected to
 *                        the login form. Default is true.
 *     - `component`    - the component to render at this path
 *     - `settings`     - name of custom dashboard settings
 *     - `wrapper`      - extra wrapper component to render around `component`
 *     - `navTitle`     - text for nav menu and/or breadcrumb (optional)
 *     - `navCategory`  - sidebar menu category this route's link should
 *                        appear in (optional). Routes without a navCategory
 *                        are excluded from the navigation menu.
 *     - `navIconSrc`   - icon path (src) for nav and/or breadcrumb (optional)
 *     - `navCondition` - a function that takes props from the sidebar and
 *                        returns a boolean indicating whether to show the
 *                        link (optional). If the user tries to manually
 *                        navigate to the route anyway, they will see the
 *                        `PageForbidden` component. Check the `ProtectedRoute`
 *                        component to see the props you have access to.
 *     - `navClassName` - an extra CSS class to apply to the nav menu link
 *
 */

const accountSettingsRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/email-alerts",
        authRequired: true,
        component: CustomDashboard,
        settings: "accountSettings",
        navTitle: "Email Alerts",
        navCategory: navCategories.emailAlerts,
        navIconSrc: accountSettingsIconSrc,
        navCondition: (props: tHasEmailNotificationAccessProps) => hasEmailNotificationAccess(props),
    },
]

const dailySummaryEmailSettingsRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/daily-summary-email-settings",
        authRequired: true,
        component: DailySummaryEmailSettings,
        settings: "dailySummaryEmailSettings",
        navTitle: "Daily Summary Email",
        navCategory: navCategories.dailySummaryEmailSettings,
        navIconSrc: dailySummaryEmailSettingsIconSrc,
        navCondition: (props: tHasEmailNotificationAccessProps) => hasEmailNotificationAccess(props),
    },
]

const analyticsRoutes: Array<tRoute> = [
    // removing this seems to disable use of any Legacy DS Custom User Links - maintain to use those links
    {
        path: "/rhumbix/analytics/profit-loss",
        authRequired: true,
        component: DailyProfitLoss,
        // leaving out the navTitle makes sure we don't show this as an individual menu item
        navCategory: navCategories.analytics,
        navIconSrc: analyticsIconSrc,
        navCondition: (props: { features: tFeatures }) =>
            props.features["timekeeping"] && getFlagEnabled("WA-7052-restore-custom-user-links"),
    },
    {
        path: "/rhumbix/analytics/unit-rate-tracker",
        authRequired: true,
        component: CustomDashboard,
        settings: "unitRateTracker",
        wrapper: ReportsPageContainer,
        navTitle: "Unit Rate Tracker",
        navCategory: navCategories.analytics,
        navIconSrc: analyticsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["budget_cost_codes"],
    },
]

const demoDashboardRoutes: Array<tRoute> = generateDashboardEntries(demoDashboardMenuList, "/rhumbix/demo/", "demo")
const betaDashboardRoutes: Array<tRoute> = generateDashboardEntries(betaDashboardMenuList, "/rhumbix/beta/", "beta")

// TODO: Use this for all company settings routes?
const baseCompanySettingsRoute: Partial<tRoute> = {
    authRequired: true,
    navCondition: (props: { user_role: tUserRole }) => ["ADMIN", "PAYROLL_ADMIN", "PM"].includes(props.user_role),
}

const projectDashboardRoute = {
    ...baseCompanySettingsRoute,
    path: projectDashboardRoutePath,
    component: CustomDashboard,
    navTitle: "Projects",
    navCategory: navCategories.companySettings,
    navIconSrc: projectSettingsIconSrc,
    settings: "projects",
}

const projectConfigDashboardRoute = {
    ...baseCompanySettingsRoute,
    path: projectConfigDashboardRoutePath,
    component: ProjectConfigDashboard,
    navTitle: "Project Configuration",
    navCategory: navCategories.companySettings,
    navIconSrc: projectSettingsIconSrc,
    navCondition: () => getFlagEnabled("hackathon-project-config"),
}

const baseFormSchemasDashboardRoute = {
    ...baseCompanySettingsRoute,
    component: CustomDashboard,
    navCategory: navCategories.companySettings,
    navIconSrc: companySettingsIconSrc,
}

const fieldFormsDashboardRoute = {
    ...baseFormSchemasDashboardRoute,
    path: "/rhumbix/company-admin/field-forms",
    settings: "fieldForms",
    navTitle: "Field Forms",
    navCondition: (props: { features: tFeatures }) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        baseFormSchemasDashboardRoute.navCondition!(props) && props.features["custom_form"],
}

const shiftExtrasDashboardRoute = {
    ...baseFormSchemasDashboardRoute,
    path: "/rhumbix/company-admin/shift-extras",
    settings: "shiftExtras",
    navTitle: "Shift Extras",
    navCondition: (props: { features: tFeatures }) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        baseFormSchemasDashboardRoute.navCondition!(props) && props.features["shift_extras"],
}

const cohortsDashboardRoute = {
    ...baseFormSchemasDashboardRoute,
    component: CustomDashboard,
    path: "/rhumbix/company-admin/cohorts",
    settings: "cohorts",
    navTitle: "Cohorts",
    navCondition: (props: { user_role: tUserRole }) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        baseFormSchemasDashboardRoute.navCondition!(props),
}

const timekeepingModifiersDashboardRoute = {
    ...baseFormSchemasDashboardRoute,
    path: "/rhumbix/company-admin/timekeeping-modifiers",
    settings: "timekeepingModifiers",
    navTitle: "Timekeeping Modifiers",
    navCondition: (props: { features: tFeatures }) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        baseFormSchemasDashboardRoute.navCondition!(props) &&
        props.features["cost_code_level_modifiers"] &&
        getFlagEnabled("WA-7632-project-specific-modifiers"),
}

const timekeepingStatusesDashboardRoute = {
    ...baseFormSchemasDashboardRoute,
    path: "/rhumbix/company-admin/timekeeping-statuses",
    settings: "timekeepingStatuses",
    navTitle: "Timekeeping Statuses",
    navIconObj: standardIcons.status as typeof React.Component,
    navIconSrc: undefined,
    navCondition: (props: { features: tFeatures }) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        baseFormSchemasDashboardRoute.navCondition!(props),
}

const companySettingsRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/company-admin/users/upload",
        authRequired: true,
        component: EmployeesUploadFromCSVForm,
        navTitle: "Team Members",
        navIconSrc: companySettingsIconSrc,
        isCompanyLevel: true,
    },
    {
        path: "/rhumbix/company-admin/users/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "employees",
        navTitle: "Team Members",
        navIconSrc: companySettingsIconSrc,
    },
    {
        path: "/rhumbix/company-admin/users",
        authRequired: true,
        component: CompanyAdminIndexRedirect,
        navTitle: "Team Members",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
    },
    {
        path: "/rhumbix/company-admin/groups/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "companyGroups",
        navTitle: "Groups",
        navIconSrc: companySettingsIconSrc,
    },
    {
        path: "/rhumbix/company-admin/groups/members/upload",
        authRequired: true,
        component: GroupMembersCSVUploadForm,
        settings: "companyGroups",
        navTitle: "Groups",
        navIconSrc: companySettingsIconSrc,
    },
    {
        path: "/rhumbix/company-admin/groups",
        authRequired: true,
        component: RelativeEditIndexRedirect,
        navTitle: "Groups",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["groups"],
    },
    {
        path: "/rhumbix/company-admin/equipment/upload",
        authRequired: true,
        component: EquipmentUploadFromCSVForm,
        navTitle: "Equipment",
        navIconSrc: companySettingsIconSrc,
        isCompanyLevel: true,
    },
    {
        path: "/rhumbix/company-admin/equipment/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "companyEquipment",
        navTitle: "Equipment",
        navIconSrc: companySettingsIconSrc,
    },
    {
        path: "/rhumbix/company-admin/equipment",
        authRequired: true,
        component: CompanyAdminEquipmentIndexRedirect,
        navTitle: "Equipment",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["equipment"],
    },
    {
        path: "/rhumbix/company-admin/integration/api",
        authRequired: true,
        component: ProtectedApiIntegration,
        settings: "apiIntegrationSettings",
        navTitle: "API Integration",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures; user_role: tUserRole }) =>
            props.features["admin.api_integration"] && props.user_role === "ADMIN",
    },
    {
        path: "/rhumbix/company-admin/message",
        authRequired: true,
        component: MassText,
        navTitle: "Message Workers",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures; user_role: tUserRole }) =>
            props.features["admin.mass_text"] && props.user_role === "ADMIN",
    },
    {
        path: "/rhumbix/company-admin/materials/upload",
        authRequired: true,
        component: MaterialCSVUploadForm,
        navTitle: "Materials",
        navIconSrc: companySettingsIconSrc,
        isCompanyLevel: true,
    },
    {
        path: "/rhumbix/company-admin/materials/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "materials",
        navTitle: "Materials",
        navIconSrc: companySettingsIconSrc,
    },
    {
        path: "/rhumbix/company-admin/materials",
        authRequired: true,
        component: CompanyAdminMaterialsIndexRedirect,
        navTitle: "Materials",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["materials"],
    },
    {
        path: picklistDashboardRoutePath,
        authRequired: true,
        component: CompanyPicklists,
        navTitle: "Picklists",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures; user_role: tUserRole }) => props.user_role === "ADMIN",
    },
    {
        path: "/rhumbix/company-admin/signatures",
        authRequired: true,
        component: CompanySignatures,
        navTitle: "Team Signatures",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures; user_role: tUserRole }) =>
            props.features["timekeeping"] && props.user_role === "ADMIN",
    },
    {
        path: "/rhumbix/company-admin/locked-time-period",
        authRequired: true,
        component: LockedTimePeriod,
        navTitle: "Locked Time Period",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures; user_role: tUserRole }) =>
            props.features["timekeeping"] && props.user_role === "ADMIN",
    },
    {
        path: "/rhumbix/company-admin/integrations",
        authRequired: true,
        component: Integrations,
        navTitle: "Integrations",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["admin.bim360"],
    },
    {
        path: "/rhumbix/company-admin/logo",
        authRequired: true,
        component: CompanyLogoUpload,
        navTitle: "Company Logo",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { user_role: tUserRole }) => props.user_role === "ADMIN",
    },
    {
        path: projectDashboardUploadRoutePath,
        authRequired: true,
        component: ProjectsUploadFromCSVForm,
        navTitle: "Projects",
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/company-admin/cost-code-controls",
        authRequired: true,
        component: CustomDashboard,
        settings: "companyCrewTypes",
        navTitle: "Cost Code Controls",
        navCategory: navCategories.companySettings,
        navIconSrc: companySettingsIconSrc,
        navCondition: (props: { features: tFeatures; user_role: tUserRole }) =>
            props.features["cost_code_controls"] && props.user_role === "ADMIN",
    },
    {
        path: "/rhumbix/company-admin/analytics/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "analyticsSettings",
        navCategory: navCategories.companySettings,
        navTitle: "Field Analytix\u2122",
        navIconSrc: companySettingsIconSrc,
        navCondition: props => {
            return props.user_role === "ADMIN" && props.analytics_dashboards.length > 0
        },
    },
    {
        path: "/rhumbix/company-admin/sso-configuration",
        authRequired: true,
        component: SSOConfiguration,
        navCategory: navCategories.companySettings,
        navTitle: "SSO Configuration",
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures; user_role: tUserRole }) =>
            props.user_role === "ADMIN" && props.features["enterprise_sso_configuration"],
    },
    {
        // Special case for navigating to project dashboard and auto-opening Create Project form.
        ...projectDashboardRoute,
        path: projectDashboardAddNewRoutePath,
        navCategory: undefined,
    },
    {
        // Standard project dashboard route
        // Needs to come after nested route definitions so that they are prioritized
        ...projectDashboardRoute,
    },
    {
        ...projectConfigDashboardRoute,
    },
    {
        ...fieldFormsDashboardRoute,
    },
    {
        ...shiftExtrasDashboardRoute,
    },
    {
        ...timekeepingModifiersDashboardRoute,
    },
    {
        ...timekeepingStatusesDashboardRoute,
    },
    {
        ...cohortsDashboardRoute,
    },
    {
        path: "/rhumbix/company-admin",
        authRequired: true,
        component: CompanyAdminIndexRedirect,
    },
]

const fieldFormRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/field-forms/quantities/edit",
        authRequired: true,
        component: QuantityEdit,
        navTitle: "Quantities",
        navCategory: navCategories.fieldForms,
        navIconSrc: fieldFormsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["admin.quantity_edit"],
    },
    {
        path: "/rhumbix/field-forms/quantities",
        authRequired: true,
        component: RelativeEditIndexRedirect,
    },
]

const projectSettingsRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/projects/cost-codes/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "costCodes",
        navTitle: "Cost Codes",
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => !props.features["budget_cost_codes"],
    },
    {
        path: "/rhumbix/projects/cost-codes/upload",
        authRequired: true,
        component: CostCodesUploadFromCSVForm,
        navTitle: "Cost Codes",
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => !props.features["budget_cost_codes"],
    },
    {
        path: "/rhumbix/projects/cost-codes",
        authRequired: true,
        component: RelativeEditIndexRedirect,
        navTitle: "Cost Codes",
        navCategory: navCategories.projectSettings,
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => !props.features["budget_cost_codes"],
    },
    {
        path: "/rhumbix/projects/budgets/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "costCodes",
        navTitle: "Budget",
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["budget_cost_codes"],
    },
    {
        path: "/rhumbix/projects/budgets/upload",
        authRequired: true,
        component: CostCodesUploadFromCSVForm,
        navTitle: "Budget",
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["budget_cost_codes"],
    },
    {
        path: "/rhumbix/projects/budgets",
        authRequired: true,
        component: RelativeEditIndexRedirect,
        navTitle: "Budget",
        navCategory: navCategories.projectSettings,
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["budget_cost_codes"],
    },
    {
        path: "/rhumbix/projects/employees/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "projectEmployees",
        navTitle: "Team Members",
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { user_role: tUserRole }) =>
            ["ADMIN", "PAYROLL_ADMIN", "PM"].includes(props.user_role),
    },
    {
        path: "/rhumbix/projects/employees/upload",
        authRequired: true,
        component: EmployeesUploadFromCSVForm,
        navTitle: "Team Members",
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/projects/employees",
        authRequired: true,
        component: RelativeEditIndexRedirect,
        navTitle: "Team Members",
        navCategory: navCategories.projectSettings,
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/projects/equipment/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "equipment",
        navTitle: "Equipment",
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/projects/equipment/upload",
        authRequired: true,
        component: EquipmentUploadFromCSVForm,
        navTitle: "Equipment",
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/projects/equipment",
        authRequired: true,
        component: RelativeEditIndexRedirect,
        navTitle: "Equipment",
        navIconSrc: projectSettingsIconSrc,
        navCategory: navCategories.projectSettings,
        navCondition: (props: { features: tFeatures }) => props.features["equipment"],
    },
    {
        path: "/rhumbix/projects/materials/edit",
        authRequired: true,
        component: CustomDashboard,
        settings: "projectMaterials",
        navTitle: "Materials",
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/projects/materials/upload",
        authRequired: true,
        component: MaterialsUploadFromCSVForm,
        navTitle: "Materials",
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/projects/materials",
        authRequired: true,
        component: RelativeEditIndexRedirect,
        navTitle: "Materials",
        navCategory: navCategories.projectSettings,
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["materials"],
    },
    {
        path: "/rhumbix/projects/invite",
        authRequired: true,
        component: ForemenInvite,
        navTitle: "Invite Foreman",
        navCategory: navCategories.projectSettings,
        navIconSrc: projectSettingsIconSrc,
    },
    {
        path: "/rhumbix/projects/pco-numbers",
        authRequired: true,
        component: CustomDashboard,
        settings: "gcReferenceNumbers",
        wrapper: ReportsPageContainer,
        navTitle: "PCO Numbers",
        navCategory: navCategories.projectSettings,
        navIconSrc: projectSettingsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["pco_number_dashboard"],
    },
    {
        path: "/rhumbix/projects",
        authRequired: true,
        component: ProjectPage,
    },
]

const reportsRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/reports/cc-accrual",
        authRequired: true,
        component: CustomDashboard,
        settings: "costCodeAccrual",
        wrapper: ReportsPageContainer,
        navTitle: "Cost Code Accrual",
        navCategory: navCategories.reports,
        navIconSrc: reportsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["timekeeping"],
    },
    {
        path: "/rhumbix/reports/cmic-export",
        authRequired: true,
        component: CmicIntegration,
        wrapper: ReportsPageContainer,
        navTitle: "CMiC Export",
        navCategory: navCategories.reports,
        navIconSrc: reportsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["cmic"],
    },
]

const timeCardsRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/time-cards/weekly",
        authRequired: true,
        component: CustomDashboard,
        settings: "weeklyTimeCard",
        wrapper: ReportsPageContainer,
        navTitle: "Weekly View",
        navCategory: navCategories.timeCards,
        navIconSrc: timeCardsIconSrc,
        navCondition: (props: { features: tFeatures }) => props.features["timekeeping"],
    },
    {
        path: "/rhumbix/time-cards/daily-time-card",
        authRequired: true,
        component: DailyTimeCard,
        wrapper: ReportsPageContainer,
        navTitle: "Daily View",
        navCategory: navCategories.timeCards,
        navIconSrc: timeCardsIconSrc,
        navCondition: (props: { features: tFeatures }) =>
            props.features["timekeeping"] && !props.features.hide_daily_view,
    },
    {
        path: "/rhumbix/time-cards/production",
        authRequired: true,
        component: CustomDashboard,
        navCategory: navCategories.timeCards,
        navTitle: "Production",
        settings: "productionTracking",
        wrapper: ReportsPageContainer,
        navCondition: (props: { features: tFeatures }) =>
            props.features.budget_cost_codes && props.features.web_production,
    },
    {
        path: "/rhumbix/time-cards/equipment-tracking",
        authRequired: true,
        component: CustomDashboard,
        settings: "equipmentTracking",
        wrapper: ReportsPageContainer,
        navTitle: "Equipment Tracking",
        navCategory: navCategories.timeCards,
        navIconSrc: timeCardsIconSrc,
        navCondition: (props: {
            company_schema_names: string
            user: { company_schema_names: string }
            features: tFeatures
        }) =>
            props.features.timekeeping &&
            props.features.equipment &&
            props.features.shift_extras &&
            (props.company_schema_names?.includes("Equipment-Tracking") ||
                props.user?.company_schema_names?.includes("Equipment-Tracking")),
    },
    {
        path: "/rhumbix/time-cards",
        authRequired: true,
        component: RelativeWeeklyIndexRedirect,
        wrapper: ReportsPageContainer,
        navCondition: (props: { features: tFeatures }) => props.features["timekeeping"],
    },
]

export const protectedRoutes: Array<tRoute> = [
    ...accountSettingsRoutes,
    ...betaDashboardRoutes,
    ...companySettingsRoutes,
    ...dailySummaryEmailSettingsRoutes,
    ...demoDashboardRoutes,
    ...fieldFormRoutes,
    ...projectSettingsRoutes,
    ...timeCardsRoutes,
    ...analyticsRoutes,
    ...reportsRoutes,
    {
        path: ["/rhumbix/export/csv", "/rhumbix/export/pdf", "/rhumbix/export"],
        authRequired: true,
        component: ExportPage,
    },
    {
        path: "/rhumbix/role-not-available",
        authRequired: true,
        component: RoleNotAvailablePage,
    },
]

export const openRoutes: Array<tRoute> = [
    {
        path: "/rhumbix/oidc-callback",
        authRequired: false,
        component: OidcRedirectHandler,
    },
    {
        path: "/rhumbix/oidc-mobile-callback",
        authRequired: false,
        component: OidcRedirectHandler,
    },
    {
        path: "/rhumbix/login/lockout",
        authRequired: false,
        component: LoginLockoutPage,
    },
    {
        path: "/rhumbix/login",
        authRequired: false,
        component: LoginPage,
    },
    {
        path: "/rhumbix/guest_login/:signed_key",
        authRequired: false,
        component: GuestLoginPage,
    },
    {
        path: GUEST_PATH,
        authRequired: false,
        component: CustomFormWrapper,
    },
    {
        path: "/rhumbix/trial/deactivated",
        authRequired: false,
        component: TrialDeactivated,
    },
    {
        path: "/rhumbix/password/forgot",
        authRequired: false,
        component: ForgotPassword,
    },
    {
        path: "/rhumbix/password/set",
        authRequired: false,
        component: SetPassword,
    },
    {
        path: "/rhumbix/password/reset/:splat?",
        authRequired: false,
        component: ResetPassword,
    },
    {
        path: "/rhumbix/password/set-new/:splat?",
        authRequired: false,
        component: SetNewPassword,
    },
    // remove with WA-5689-update-external-form-view
    {
        path: "/rhumbix/custom-form/create/:project_id/:schema_id",
        authRequired: false,
        component: CustomFormWrapper,
    },
    {
        path: "/rhumbix/custom-form/:id",
        authRequired: false,
        component: CustomFormWrapper,
    },
    {
        path: PDF_PATH,
        authRequired: false,
        component: CustomFormWrapper,
    },
    {
        path: "/rhumbix/sign/txt/:id",
        authRequired: false,
        component: TKTextToSignRouter,
    },
    {
        path: "/rhumbix/sign/:id",
        authRequired: false,
        // when we remove WA-5644-tk-signatures - this can be swapped out for TKSignatureWrapper
        // we have to make a special router so we could toggle between the diff signature pads via a query param
        component: TKSignatureRouter,
    },
    {
        path: "/rhumbix/page-forbidden",
        authRequired: false,
        component: PageForbidden,
        isErrorPage: true,
    },
    {
        path: "/rhumbix",
        authRequired: false,
        component: LoadingIndicator,
    },
]

export const routes: Array<tRoute> = [...protectedRoutes, ...openRoutes]

// Our catch-all 404 page does not have a defined path, so it will match
// anything that did not match the above routes. THIS MUST COME LAST.
routes.push({
    path: undefined,
    authRequired: false,
    component: PageNotFound,
    isErrorPage: true,
})

export const routesWithoutSidebar: Array<string> = [
    "/rhumbix/custom-form/create/:project_id/:schema_id",
    "/rhumbix/custom-form/:id",
    "/rhumbix/sign/:id",
    "/rhumbix/guest_login/:signed_key",
    PDF_PATH,
    GUEST_PATH,
]

// Group routes by navigation category
export const menuRoutes: tMenuCategoriesRoutes = routes.reduce((acc: tMenuCategoriesRoutes, route: tRoute) => {
    const navCategory: tNavCategoriesKeys = route.navCategory as tNavCategoriesKeys
    if (navCategory !== undefined) {
        acc[navCategory] = Array.isArray(acc[navCategory]) ? [...acc[navCategory], route] : [route]
    }
    return acc
}, {} as tMenuCategoriesRoutes)

export default routes
